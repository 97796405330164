import { render, staticRenderFns } from "./RiderDelete.vue?vue&type=template&id=ef988cba&scoped=true"
import script from "./RiderDelete.vue?vue&type=script&lang=js"
export * from "./RiderDelete.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef988cba",
  null
  
)

export default component.exports